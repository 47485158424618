import React, { useEffect } from 'react';
import Page from './Page';
import VideoCarousel from './VideoCarousel';
import { SocialIcon } from 'react-social-icons';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
// import bannerImgBottom from './images/banner_bottom.webp';
import { useOutletContext } from "react-router-dom";


const InstagramEmbed = ({ postUrl }) => {
  useEffect(() => {
    // Load Instagram embed script
    const script = document.createElement('script');
    script.src = '//www.instagram.com/embed.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <blockquote 
      className="instagram-media" 
      data-instgrm-permalink={postUrl}
      data-instgrm-version="14"
    />
  );
};

const InstagramFeed = () => {
  const posts = [
    'https://www.instagram.com/p/DIJz-9QxRzw/',
    'https://www.instagram.com/p/DH4NHkIv8wH/',
    'https://www.instagram.com/p/DHlz7pTgd66/',
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      {posts.map((url, index) => (
        <InstagramEmbed key={index} postUrl={url} />
      ))}
    </div>
  );
};

const Home = () => {
  const [
    data,
    gallery,
    socials,
    // banner,
    // width,
  ] = useOutletContext();

  return (
    <>
      <Page
        id={"about"}
        data={data?.about}
        sectionColor={'dark'}
        leftAlignText={true}
      />

      <div id={`music`} className={`section centered-section fade-in w-full bg-[#c9b354]`}>
        <h2 className={`text-center text-6xl text-[#111d0a] pt-6`}>{`Music`}</h2>
        <div className="flex flex-col w-full items-center justify-center md:flex-row">
          <div className="w-full md:w-1/3 flex flex-col pt-10 pl-10 pr-10 md:pb-10">
            <a className="img-link" href="https://found.ee/CatoCoversVol1" rel="noreferrer" target="_blank" alt='NEW "Cato Covers, Vol.1" Out Now'>
              <img src={'https://api.louiscato.com/wp-content/uploads/2024/09/CatoCovers_6.webp'} alt={'NEW "Cato Covers, Vol.1" Out Now'}/>
            </a>
            <h3 className={`text-[#111d0a] text-3xl mt-4 text-center`}>{'NEW "Cato Covers, Vol.1" Out Now'}</h3>
            <div className={`flex items-center justify-center`}>
              <a className="bg-[#c9b354] text-[#111d0a] hover:bg-[#111d0a] hover:text-[#c9b354] p-4 mt-2 rounded-lg transition-all  flex items-center justify-center" href="https://found.ee/CatoCoversVol1" rel="noreferrer" target="_blank" alt='NEW "Cato Covers, Vol.1" Out Now'>
                <span className={`text-xl uppercase`}>{'Listen Here'}</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="fade-in flex flex-col w-full items-center justify-center md:flex-row bg-[#111d0a]">
        <div className="w-full md:w-1/3 flex flex-col pt-10 pl-10 pr-10 md:pb-10">
          <a className="img-link" href="https://found.ee/DPT5Pk" rel="noreferrer" target="_blank" alt='"Why Try Love"'>
            <img src={'https://api.louiscato.com/wp-content/uploads/2024/09/whyTryLove.webp'} alt={'"Why Try Love"'}/>
          </a>
          <h3 className={`text-[#c9b354] text-3xl mt-4 text-center`}>{'"Why Try Love"'}</h3>
          <div className={`flex items-center justify-center`}>
            <a className="bg-[#111d0a] text-[#c9b354] hover:bg-[#c9b354] hover:text-[#111d0a] p-4 mt-2 rounded-lg transition-all  flex items-center justify-center" href="https://found.ee/DPT5Pk" rel="noreferrer" target="_blank" alt='"Why Try Love"'>
              <span className={`text-xl uppercase`}>{'Listen Here'}</span>
            </a>
          </div>
        </div>
        <div className="w-full md:w-1/3 flex flex-col p-10">
          <a className="img-link" href="https://found.ee/louiscatoreflections" rel="noreferrer" target="_blank" alt='"Reflections"'>
            <img src={"https://api.louiscato.com/wp-content/uploads/2024/09/1691737689_cover.jpg"} alt={'"Reflections"'}/>
          </a>
          <h3 className={`text-[#c9b354] text-3xl mt-4 text-center`}>{'"Reflections"'}</h3>
          <div className={`flex items-center justify-center`}>
            <a className="bg-[#111d0a] text-[#c9b354] hover:bg-[#c9b354] hover:text-[#111d0a] p-4 mt-2 rounded-lg transition-all  flex items-center justify-center" href="https://found.ee/louiscatoreflections" rel="noreferrer" target="_blank" alt='"Reflections"'>
            <span className={`text-xl uppercase`}>{'Listen Here'}</span>
            </a>
          </div>
        </div>
      </div>

      <VideoCarousel
        id={"videos"}
        data={data?.videos}
        sectionColor={'light'}
      />

      {/* <div className="fade-in promo-banner" id={"reflections"}>
        <img src={banner} alt={"Louis Cato's single 'Reflections' out now"}/>
      </div> */}

      {/* <Page
        id={"reflections-section"}
        data={data?.reflections}
        sectionColor={'reflections'}
        width={width}
        bg={bannerImgBottom}
      /> */}


      <div id={`catocovers`} className="section centered-section section-dark">
        <div className="video-content">
          <h2>#catocovers</h2>
          <div className="video-container">
            <InstagramFeed />
          </div>
            <a className="button-link" rel="noreferrer" href={"https://www.instagram.com/reallouiscato/"} target="_blank">View on Instagram</a>
        </div>
      </div>

      {/* {instagramSuccess &&
      <div id={`catocovers`} className="section centered-section section-dark">
        <div className="video-content">
          <h2>#catocovers</h2>
          <div className="video-container">
            {latestInsta?.length && latestInsta.map((ig, index) => {
              return (
                <video
                    key={index}
                    controls
                    width="250"
                    src={ig.media_url}
                    poster={ig.thumbnail_url}
                  >
                    Sorry, your browser doesn't support embedded videos. <a rel="noreferrer" href="https://www.instagram.com/reallouiscato/" target="_blank">Go to Instagram to watch</a>
                  </video>
              );
            })}
          </div>
          {latestInsta?.length && latestInsta[0].permalink &&
            <a className="button-link" rel="noreferrer" href={latestInsta[0].permalink} target="_blank">View on Instagram</a>
          }
        </div>
      </div>
      } */}

      <div id={`shows`} className="section centered-section section-light">
        <div className="text-content">
          <h2>Shows</h2>
          <div id="seated-55fdf2c0" data-artist-id="b8803384-a89e-46c7-b871-f902f95a6ef6" data-css-version="3"></div>
        </div>
      </div>

      <div id={`appearances`} className="section centered-section section-dark">
        <div className="text-content">
        <h2>Appearances</h2>
        </div>
        <div className="appearances-container">
          <Carousel
            autoPlay={true}
            interval={3000}
            infiniteLoop={true}
            stopOnHover={false}
            centerMode={true}
            centerSlidePercentage={100}
            dynamicHeight={false}
            useKeyboardArrows={true}
            showArrows={true}
          >
            {gallery?.length && Array.isArray(gallery) && gallery.map((galleryItem, index) => {
              return <div key={index}>
                  <img src={galleryItem?._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url} alt={galleryItem?._embedded['wp:featuredmedia'][0].alt_text} />
                  <div className="gallery-caption" dangerouslySetInnerHTML={{ __html: galleryItem.excerpt.rendered }}/>
              </div>
            })}
          </Carousel>
        </div>
      </div>

      <div id={`contact`} className="section section-light">
        <div className="text-content">
          {data?.contact && <h2 dangerouslySetInnerHTML={{ __html: data.contact.title.rendered }} />}
          <div className="two-columns">
            {data?.contact && <div dangerouslySetInnerHTML={{ __html: data.contact.content.rendered }} />}
            <div className="socials">
              <div className="a-social">
                <SocialIcon
                  url={socials.twitter}
                  bgColor={'#111d0a'}
                />
                <a href={socials.twitter} rel="noreferrer" target="_blank">Twitter</a>
              </div>
              <div className="a-social">
                <SocialIcon
                  url={socials.instagram}
                  bgColor={'#111d0a'}
                />
                <a href={socials.instagram} rel="noreferrer" target="_blank">Instagram</a>
              </div>
              <div className="a-social">
                <SocialIcon
                  url={socials.tiktok}
                  bgColor={'#111d0a'}
                />
                <a href={socials.tiktok} rel="noreferrer" target="_blank">TikTok</a>
              </div>
              <div className="a-social">
                <SocialIcon
                  url={socials.facebook}
                  bgColor={'#111d0a'}
                />
                <a href={socials.facebook} rel="noreferrer" target="_blank">Facebook</a>
              </div>
              <div className="a-social">
                <SocialIcon
                  url={socials.youtube}
                  bgColor={'#111d0a'}
                />
                <a href={socials.youtube} rel="noreferrer" target="_blank">YouTube</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;